<template>
  <div class="popup-block">
    <div class="popup">
      <div class="popup-header">
        <div class="logo">
          <img v-if="logo" alt="SimplyVideo" :src="logo" />
          <img v-else-if="businessLogo" alt="SimplyVideo" :src="businessLogo" />
        </div>
        <div class="support-alert">Support Alert!</div>
      </div>
      <div v-if="callData.user.name && callData.user.last_name" class="popup-body">
        <div class="name-and-status">
          <div class="short-name">
            {{callData.user.name[0]}}{{callData.user.last_name[0]}}
          </div>
          <div class="user-block">
            <p class="name">
              <span>{{callData.user.name}} </span>
              <span>{{callData.user.last_name}}</span>
            </p>
            <p class="status">is calling...</p>
          </div>
        </div>
        <div class="call-buttons">
          <button class="btn-call decline" @click="rejectCall">
            <i class="fa fa-times" />
          </button>
          <button class="btn-call accept" @click="acceptCall">
            <i class="fa fa-check" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {brandingStore} from '@/store/__STORE_branding';
import {callStore} from '@/store/__STORE_call';

export default {
  emit: ['close-modal', 'accept-call'],
  props: {
    callData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sound: new Audio(),
    };
  },
  computed: {
    logo() {
      return brandingStore.logo;
    },
    businessLogo() {
      return brandingStore.businessLogo;
    },
    callUserId() {
      return this.callData?.user?.id;
    },
  },
  mounted() {
    this.sound.src = 'phone-ringing.mp3';
    this.sound.autoplay = true;
    this.sound.muted = false;
    setTimeout(() => {
      this.stopCall();
    }, 25000);
  },
  destroyed() {
    this.stopCall();
  },
  methods: {
    stopCall() {
      this.sound.muted = true;
      this.$emit('close-modal');
    },
    rejectCall() {
      callStore.callToPhonebook({
        status: 'reject',
        callHistoryId: this.callData.callHistoryId,
        userId: this.callUserId,
      });
      this.stopCall();
    },
    acceptCall() {
      callStore.callToPhonebook({
        status: 'accept',
        callHistoryId: this.callData.callHistoryId,
        userId: this.callUserId,
      }).then(() => {
        window.open(this.callData.url + `/${this.callData.user.linkToCall}`);
      });
      this.stopCall();
    },
  },
};
</script>

<style lang='scss' scoped>
@import '@/assets/scss/__variables';

.popup-block {
  width: 100%;
  max-width: 519px;
  min-width: 350px;
  position: absolute;
  // left: calc(50vw - 295px);
  left: calc(50vw - 140px);
  top: calc(50vh - 104px);
  padding: 0 20px;

  .popup {
    width: 100%;
    background: #0d0c14;
    border-radius: 12px;

    .popup-header {
      width: 100%;
      height: 48px;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        img {
          width: auto;
          height: auto;
          max-height: 35px;
        }
      }

      .support-alert {
        height: 24px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        padding: 2px 12px;
        border-radius: 40px;
        background: rgba(255, 255, 255, 0.12);
        color: $white;
      }
    }

    .popup-body {
      width: 100%;
      min-height: 95px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      padding: 0 24px;
      background: #272433;

      .call-buttons {
        display: flex;

        .btn-call {
          width: 56px;
          height: 56px;
          font-size: 24px;
          border: 0;
          border-radius: 50%;
          margin-left: 24px;
          color: $white;
        }

        .accept {
          background-color: #4bb34b;
        }

        .decline {
          background-color: #e64646;
        }
      }
    }
  }

  .name-and-status {
    width: 100%;
    max-width: 280px;
    display: flex;
    align-items: center;

    .short-name {
      min-width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      color: #3e3e40;
      background: linear-gradient(180deg, #acdbdb 54.01%, #83c0c0 100%);
    }

    @media all and (max-width: $breakpoint-sm) {
      .short-name {
        display: none;
      }
    }

    .short-name {
      margin-right: 12px;
    }

    .user-block {
      max-width: 220px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name {
        display: block;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 135%;
        color: $white;

        span {
          word-wrap: break-word;
          word-break: break-all;
        }
      }

      .status {
        display: inline-block;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: rgba(255, 255, 255, 0.8);
        clip-path: inset(0 1ch 0 0);
        animation: loading 1s steps(7) infinite;
      }

      @keyframes loading {
        to {
          clip-path: inset(0 -1ch 0 0);
        }
      }
    }

    p {
      margin: 0;
    }
  }
}

@media all and (max-width: $tabletPortraitMin) {
  .popup-block {
    left: calc(50vw - 260px);
  }
}

@media all and (max-width: $breakpoint-sm) {
  .popup-block {
    left: 0;
    margin: 0 auto;
  }
}
</style>
