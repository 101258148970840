<template>
  <main id="site-wrapper">
    <section class="d-flex">
      <div class="section section-block">
        <div class="container-fluid">
          <div class="section-header">
            <div class="section-header-title-group">
              <h1>Expert Seat</h1>
              <p>We are tracking your availability by checking your web camera and microphone work each 15 seconds.</p>
            </div>
          </div>
        </div>

        <div v-if="checkFullName" class="profile-status">
          <div class="name-and-status">
            <div class="short-name">
              {{ authInfo.name[0] }}{{ authInfo.last_name[0] }}
            </div>
            <div class="user-block">
              <p class="text name">
                {{ authInfo.name }} {{ authInfo.last_name }}
              </p>
              <p class="text">
                {{ authInfo.working_hours_today }} ({{ timezone }})
              </p>
            </div>
          </div>
          <div v-if="newStatus" class="multiselect-block">
            <multiselect
              v-model="newStatus"
              track-by="id"
              label="name"
              placeholder="Status"
              :searchable="false"
              :show-labels="false"
              :allow-empty="false"
              :options="statuses"
              :disabled="isStatusDisabled"
              @input="changeStatus"
            >
              <template
                slot="singleLabel"
                slot-scope="props"
              >
                <span class="d-flex align-items-center">
                  <span class="option-icon" :class="{'avaible': props.option.id === 1}"></span>
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.status }}</span>
                  </span>
                </span>
              </template>
              <template
                slot="option"
                slot-scope="props"
              >
                <span class="option__desc d-flex align-items-center">
                  <span class="option-icon" :class="{'avaible': props.option.id === 1}"></span>
                  <span class="option__title">{{ props.option.option }}</span>
                </span>
              </template>
            </multiselect>
          </div>
        </div>
      </div>

      <div class="call-history">
        <p class="call-history-title">Last calls</p>
        <div class="name-and-status p-3" v-for="call in callHistory" :key="call.id">
          <template v-if="['missed', 'reject'].includes(call.call_status)">
            <div class="short-name">
              {{ call.host_user.name[0] }}{{ call.host_user.last_name[0] }}
            </div>
            <div class="user-block">
              <p class="text name">
                {{ call.host_user.name }} {{ call.host_user.last_name }}
              </p>
              <div>
                <p class="text missed">
                  {{ call.call_status === 'missed' ?
                    'Missed' :
                    'Rejected'
                  }} {{ timeDifference(call.start_time, call.current_time) }} <a
                    v-if="call.call_status === 'missed'"
                    href=""
                    class="pl-2"
                    @click.prevent="joinToMissedCall(call)"
                  >
                    Join
                  </a>
                </p>
              </div>
            </div>
          </template>
          <template v-else-if="call.call_status === undefined">
            <div class="short-name">
              {{ call.host.name[0] }}
            </div>
            <div class="user-block">
              <p class="text name">
                {{ call.host.name }}
              </p>
              <div>
                <p class="text gray">
                  Call {{ timeDifference(call.end_time, call.current_time) }}
                </p>
                <p class="text gray">
                  Duration: {{ countDuration(call.duration) }}
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>

      <call-modal
        v-if="isCallFromPhonebook"
        :callData="callPhonebookData"
        @close-modal="closeCallModal"
      />
    </section>
  </main>
</template>

<script>
import CallModal from '@/components/CallModal.vue';

import {userStore} from '@/store/__STORE_user';
import {expertStore} from '@/store/__STORE_expert';
import {callStore} from '@/store/__STORE_call';

import EnumerateDevicesMixin from '@/mixins/EnumerateDevicesMixin';

import Multiselect from 'vue-multiselect';

export default {
  name: 'ExpertSeat',
  components: {
    CallModal,
    Multiselect,
  },
  mixins: [EnumerateDevicesMixin],
  data() {
    return {
      timeout: null,
      newStatus: null,
      pusher: null,
      statuses: [
        {
          id: 1,
          status: 'Available',
          option: 'Available',
          time: '0',
        },
        {
          id: 2,
          status: 'Busy',
          option: 'Busy (10min)',
          time: '600',
        },
        {
          id: 3,
          status: 'Busy',
          option: 'Busy (30min)',
          time: '1800',
        },
        {
          id: 4,
          status: 'Busy',
          option: 'Busy (60min)',
          time: '3600',
        },
      ],
    };
  },
  computed: {
    authInfo() {
      return userStore.__GETTERuserProfile;
    },
    checkFullName() {
      return this.authInfo?.name && this.authInfo?.last_name;
    },
    isStatusDisabled() {
      return this.authInfo.working_hours_today === 'Working hours today: No';
    },
    timezone() {
      const gtm = this.authInfo?.timezone_offset ? ' GMT' + this.authInfo?.timezone_offset : '';
      return this.authInfo?.timezone ? this.authInfo?.timezone + gtm : '';
    },
    isCallFromPhonebook() {
      return callStore.__GETTERcallPhonebookData?.user?.id ? true : false;
    },
    callPhonebookData() {
      return callStore.__GETTERcallPhonebookData;
    },
    callHistory() {
      return expertStore.__GETTERcallHistory.filter((item) => item?.call_status !== 'accept' );
    },
  },
  watch: {
    'authInfo.is_available': {
      deep: true,
      immediate: true,
      handler(val) {
        this.newStatus = val ? this.statuses[0] : this.statuses[1];
      },
    },
  },
  mounted() {
    userStore.getUserProfile().then(() => {
      callStore.getCallFromPhonebook({id: this.authInfo.id, isCall: true}).then((res) => this.pusher = res);
    });
    expertStore.getCalls();
    navigator.mediaDevices.addEventListener('devicechange', () => {
      this.initHardware();
    });
    this.initHardware();
    this.timeout = setInterval(() => {
      this.initHardware();
      expertStore.getCalls();
    }, 15000);
    if (!('Notification' in window)) {
      alert('Your brouser does not support notifications');
    } else if (Notification.permission !== 'granded') {
      Notification.requestPermission();
    }
  },
  destroyed() {
    clearInterval(this.timeout);
    if (this.pusher) this.pusher.unsubscribe(`private-user.${this.authInfo.id}`);
  },
  methods: {
    // Check devices status
    initHardware() {
      this.enumerateDevices(() => {
        let audio = true;
        let video = true;

        const videoConstraints = this.cameras.reduce((all, camera) => {
          if (camera?.deviceId?.length > 0) {
            all.push({
              deviceId: camera.deviceId,
              label: camera.label,
            });

            return all;
          }
        }, []);

        const audioConstraints = this.microphones.reduce((all, microphone) => {
          if (microphone?.deviceId?.length > 0) {
            all.push({
              deviceId: microphone.deviceId,
              label: microphone.label,
            });

            return all;
          }
        }, []);

        // Check if there is preselected microphone ID saved in localStorage
        const microphoneDeviceIdFromLocalStorage = localStorage.getItem('microphoneDeviceIdKey');
        if (microphoneDeviceIdFromLocalStorage) {
          const exists = !!audioConstraints?.find((microphone) => microphone?.deviceId === microphoneDeviceIdFromLocalStorage);

          if (exists) {
            audio = {deviceId: microphoneDeviceIdFromLocalStorage};
          }
        }

        // Check if there is preselected camera ID saved in localStorage
        const cameraDeviceIdFromLocalStorage = localStorage.getItem('cameraDeviceIdKey');
        if (cameraDeviceIdFromLocalStorage) {
          const exists = !!videoConstraints?.find((camera) => camera?.deviceId === cameraDeviceIdFromLocalStorage);

          if (exists) {
            video = {deviceId: cameraDeviceIdFromLocalStorage};
          }
        }

        const constraints = {video, audio};

        if (constraints.video.deviceId === null || constraints.video.deviceId === '') {
          constraints.video = false;
        }

        if (constraints.audio.deviceId === null || constraints.audio.deviceId === '') {
          constraints.audio = false;
        }

        // Check status of output devices in your technical equipment
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          stream.getTracks().forEach((track) => track.stop());
          expertStore.setBusy({
            busy: false,
            time: '20',
            manual: false,
          });
        }).catch(() => {
          expertStore.setBusy({
            busy: true,
            time: '20',
            manual: false,
          });
        }).finally(() => {
          userStore.getUserProfile();
        });
      });
    },
    closeCallModal() {
      callStore.__setDataFormPhonebookCall(null);
    },
    changeStatus() {
      expertStore.setBusy({
        busy: this.newStatus.status === 'Available' ? false : true,
        time: this.newStatus.time,
        manual: true,
      });
    },
    timeDifference(callTime, current) {
      const callDate = this.$moment(callTime);
      const currentDate = this.$moment(current);

      const yearDiffBetweenDates = currentDate.diff(callDate, 'years');
      if (yearDiffBetweenDates) return `${yearDiffBetweenDates}y ago`;

      const monthDiffBetweenDates = currentDate.diff(callDate, 'months');
      if (monthDiffBetweenDates) return `${monthDiffBetweenDates}mth ago`;

      const dayDiffBetweenDates = currentDate.diff(callDate, 'days');
      if (dayDiffBetweenDates) return `${dayDiffBetweenDates}d ago`;

      const hourDiffBetweenDates = currentDate.diff(callDate, 'hours');
      if (hourDiffBetweenDates) return `${hourDiffBetweenDates}h ago`;

      const minutesDiffBetweenDates = currentDate.diff(callDate, 'minutes');
      return `${minutesDiffBetweenDates}min ago`;
    },
    joinToMissedCall(callData) {
      if (callData?.meeting_room?.webrtc?.alias) {
        window.open(`${callData.url}/${callData.meeting_room.webrtc.alias}`);
      } else this.$toast.error('This call doesn\'t have a meeting room');
    },
    getFullName(participants, initials = false) {
      const calledUser = participants.find(({userId}) => this.authInfo.id !== userId);
      return initials ? `${calledUser.display_name[0]}` : `${calledUser.display_name}`;
    },
    countDuration(duration) {
      if (duration > 3600) {
        const hours = Math.floor(duration/3600);
        const minutes = Math.floor((duration - hours * 3600)/60);
        const seconds = Math.floor((duration - (hours * 3600 + minutes * 60)));
        return `${hours}h ${minutes}min ${seconds}sec`;
      } else if (duration > 60) {
        const minutes = Math.floor((duration)/60);
        const seconds = Math.floor((duration - minutes* 60));
        return `${minutes}min ${seconds}sec`;
      } else return duration + 'sec';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/__variables";

.section-block {
  width: 100%;

  .profile-status {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .multiselect-block {
      min-width: 175px;
      border-radius: 8px 8px 0px 0px;
      padding-top: 20px;

      .option-icon {
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border-radius: 50%;
        background-color: #E64646;
      }

      .avaible {
        background-color: #4BB34B;
      }
    }
  }
}

.call-history {
  min-width: 300px;
  height: calc(100vh - 70px);
  background-color: $white;
  overflow-y: auto;

  .call-history-title {
    padding: 10px 10px 0 10px;
    color: $color-gray;
    margin: 0;
  }
}

.name-and-status {
  display: flex;
  align-items: center;

  .short-name {
    width: 48px;
    min-width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #3e3e40;
    background: linear-gradient(180deg, #acdbdb 54.01%, #83c0c0 100%);
  }

  .user-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;

    .text {
      font-style: normal;
      font-size: 18px;
      line-height: 135%;
      color: #3E3E40;
    }

    .gray {
      color: $color-gray;
    }

    .missed {
      font-weight: bold;
      color: $red;

      a {
        text-decoration: underline;
        padding-bottom: 5px;
      }
    }

    .name {
      font-weight: 700;
    }
  }

  p {
    margin: 0;
  }
}
</style>
